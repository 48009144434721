const getHeight = (businessItems) => {
  let highest = 0
  businessItems.forEach((item) => {
    if (item.clientHeight > highest) {
      highest = item.clientHeight
    }
  })
  return highest
}

const setHeights = (businessItems, height) => {
  businessItems.forEach((item) => {
    height ? item.style.height = `${height}px` : item.style.height = null
  })
}

const equalHeightBusinessItems = () => {
  const businessItems = document.querySelectorAll('.business-label-block .block-item')
  if (window.innerWidth < 992) {
    setHeights(businessItems, null)
  } else {
    setHeights(businessItems, null)
    const h = getHeight(businessItems)
    setHeights(businessItems, h)
  }
}

window.addEventListener('resize', equalHeightBusinessItems)

equalHeightBusinessItems()