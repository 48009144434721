// Custom carousel
 
var initCustomCarousel = (carouselId) => {

  const myCarousel = document.getElementById(carouselId)
  const scrollContainer = myCarousel.querySelector('.custom-carousel-inner')
  const carouselNext = myCarousel.querySelector('.carousel-control-next')
  const carouselPrev = myCarousel.querySelector('.carousel-control-prev')
  const carouselNextIcon = carouselNext.querySelector('.carousel-control-next-icon')
  const carouselPrevIcon = carouselPrev.querySelector('.carousel-control-prev-icon')

  var calculateControlPos = () => {
    try {
      // carouselHeight / 2 = to get icon top position, image client height / 2 to get to the center of image. 
      let controlPos = ((carouselNext.clientHeight / 2) - (scrollContainer.querySelector('.image').clientHeight / 2)) * -1
      carouselNextIcon.style.top = `${controlPos}px`
      carouselPrevIcon.style.top = `${controlPos}px`
    } catch {
      // ignore, use default
    }

    if (scrollContainer.clientWidth === scrollContainer.scrollWidth) {
      carouselNext.setAttribute('disabled', '')
      carouselPrev.setAttribute('disabled', '')
    }
  }
  document.addEventListener('DOMContentLoaded', function () {
    calculateControlPos()
  })

  window.addEventListener('resize', function () {
    const elPadding = (myCarousel.querySelector('.custom-carousel-inner .active').clientWidth - myCarousel.querySelector('.custom-carousel-inner .active a').clientWidth) / 2
    scrollContainer.scrollTo(myCarousel.querySelector('.custom-carousel-inner .active').offsetLeft + elPadding, 0)
    calculateControlPos()

    setTimeout(() => {
      if (20 < ((scrollContainer.scrollWidth - scrollContainer.clientWidth) - scrollContainer.scrollLeft)) {
        carouselNext.removeAttribute('disabled')
      }
    }, 1000)
  })

  var setActive = () => {
    let firstVisibleElement = scrollContainer.querySelector('li.active')
    let firstVisibleElementPos = 10000
    firstVisibleElement.classList.remove('active')

    scrollContainer.querySelectorAll('li').forEach((el) => {
      let elPos = el.getBoundingClientRect().x
      if (elPos > 0 && elPos < firstVisibleElementPos) {
        firstVisibleElement = el
        firstVisibleElementPos = elPos
      }
    })

    firstVisibleElement.classList.add('active');
  }

  carouselNext.addEventListener('click', function (e) {

    const scrollStep = scrollContainer.clientWidth
    const currentScrollPos = scrollContainer.scrollLeft
    const newPos = currentScrollPos + scrollStep
    const maxPos = scrollContainer.scrollWidth - scrollContainer.clientWidth
    scrollContainer.scrollTo(newPos, 0)

    if (newPos >= maxPos) {
      carouselNext.setAttribute('disabled', '')
      carouselPrev.removeAttribute('disabled')
    } else {
      carouselNext.removeAttribute('disabled')
    }

    if (newPos > 0) {
      carouselPrev.removeAttribute('disabled')
    }

    setTimeout(() => {
      setActive()
    }, 1000)
  })

  carouselPrev.addEventListener('click', function (e) {

    const scrollStep = scrollContainer.clientWidth
    const currentScrollPos = scrollContainer.scrollLeft
    const newPos = currentScrollPos - scrollStep
    const maxPos = scrollContainer.scrollWidth - scrollContainer.clientWidth
    scrollContainer.scrollTo(newPos, 0)

    if (newPos <= 0) {
      carouselPrev.setAttribute('disabled', '')
      carouselNext.removeAttribute('disabled')
    } else {
      carouselPrev.removeAttribute('disabled')
    }

    if (newPos < maxPos) {
      carouselNext.removeAttribute('disabled')
    }

    setTimeout(() => {
      setActive()
    }, 1000)
  })
}

document.querySelectorAll('.custom-carousel').forEach((carousel) => {
  if (carousel.classList.contains('offering-promotion')) {
    if (!carousel.closest('.disable-carousel-effect')) {
      initCustomCarousel(carousel.id)
    }
  } else {
    initCustomCarousel(carousel.id)
  }
})