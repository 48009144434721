import { heroHeightCalculator } from "./heroHeightCalculator";

if (document.body.classList.contains('frontpage') && document.querySelector('.hero-arrow-down')) {
  document.querySelector('.hero-arrow-down').addEventListener('click', function () {
    window.scrollTo({ top: document.querySelector('.hero-block, .hero-carousel-wrapper').scrollHeight, left: 0, behavior: 'smooth' })
  });
}

export const hero = document.querySelector('#heroContainer')

if (hero && hero.classList.contains('hero-slider')) {
  hero.querySelector('.carousel-item').classList.add('active')
}

if (hero && hero.classList.contains('hero-block')) {
  heroHeightCalculator()
  window.addEventListener('resize', heroHeightCalculator)
}

document.querySelectorAll('video + div .playbtn').forEach((btn) => {
  btn.addEventListener('click', function (e) {
    var video = document.querySelector(e.target.getAttribute('data-target'))
    video.play()
    video.setAttribute('controls', true)
    e.target.classList.add('d-none')
  })
})