import { createHtml } from './common-functions'

const findAppendPos = (targetLi) => {

  const targetLiScrollTop = targetLi.offsetTop
  let appendLi

  Array.from(document.querySelectorAll('.webinarlistingpage #listWrapper li')).every((li) => {
    if (li.offsetTop > targetLiScrollTop) {
      return false
    }
    appendLi = li
    return true
  })

  return appendLi
}

export const createVideoContainer = (element, title, closeTxt) => {
  const targetElement = document.getElementById(element)
  const videoUrl = targetElement.getAttribute('data-video-url')
  const currentPos = window.scrollY

  const videoIframe = targetElement.getAttribute('data-youtube') === 'true' && videoUrl ?
    `<iframe id="ytvideo" frameborder="0" allowfullscreen scrolling="no" allowtransparency="true" title="Youtube video: ${title}" src="${videoUrl}?wmode=transparent&enablejsapi=1&version=3&playerapiid=ytplayer" wmode="Opaque"></iframe>` :
    `<iframe src="${videoUrl}" title="Video: ${title}"></iframe>`

  const videoHtmlString = `<li id="video-${element}" class="col-12 video-container">
      <div class="bg-white">
        <div class="d-block d-md-none p-3">
          <h4 class="title mb-0">${title}</h4>
        </div>
        <div class="video ratio ratio-16x9">${videoIframe}</div>
        <div class="row p-3">
          <div class="col-6 d-none d-md-block">
            <h4 class="title mb-0">${title}</h4>
          </div>
          <div class="col-12 col-md-6 text-end">
            <a role="button" type="button" class="btn btn-peach">${closeTxt}</a>
          </div>
        </div>
      </div>
    </li>`

  const videoTemplate = createHtml(videoHtmlString)

  const closeHandler = (e) => {
    e.target.closest('.video-container').remove()
    window.scrollTo(0, currentPos)
    targetElement.setAttribute('aria-expanded', 'false')
  }

  videoTemplate.querySelector('.btn').addEventListener('click', closeHandler)

  const insertBeforeElement = findAppendPos(targetElement.closest('li'))
  insertBeforeElement.after(videoTemplate)
  videoTemplate.scrollIntoView({ behavior: "smooth", block: "center" })
  targetElement.setAttribute('aria-expanded', 'true')
}