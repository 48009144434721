export const getExpert = async (modal) => {

  if (!modal.hasAttribute('data-fetched')) {
    const url = `${window.location.pathname}/GetExpertDetails?id=${modal.getAttribute('data-content-id')}`

    await fetch(url, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      if (!res.ok && res.status !== 404) {
        return Promise.reject(res)
      }
      return res.status !== 404 ? res.text() : null
    }).then(data => {
      if (!data) return null
      const parser = new DOMParser()
      return parser.parseFromString(data, "text/html")
    }).then((el) => {
      modal.querySelector('.modal-body').appendChild(el.querySelector('body > div'))
      modal.setAttribute('data-fetched', '')
    }).catch(error => {
      console.error(`Error from API: ${error.status} - ${error.statusText}`)
    })
  }
}