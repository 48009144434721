import { hero } from "./main";

export function heroHeightCalculator() {
    let headerHeight = 204;
    const heroContent = hero.querySelector('.container-full');
    const minHeight = heroContent.clientHeight + 300;
    const heroImage = hero.querySelector('.hero-image');

    if (window.innerWidth > 991.98) {

        if (!document.body.classList.contains('scrolling')) {
            headerHeight = document.querySelector('header').clientHeight;
        }

        heroContent.style.top = `${headerHeight}px`;
    }

    heroImage.style.minHeight = null;

    if (minHeight > heroImage.clientHeight) {
        heroImage.style.minHeight = `${minHeight}px`;
    }
}