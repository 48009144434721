var searchWithSuggestion = (searchCollapse, query, inputField) => {
  inputField.value = query
  searchCollapse.closest('form').submit()
}

var createAutoCompleteList = (response, searchCollapse, inputField, autocompleteWrapper, query) => {
  if (response?.length > 0) {
    const ul = searchCollapse.querySelector('.autocomplete-wrapper ul')
    const ulId = ul.getAttribute('id')

    response.forEach((hit, index) => {
      const li = document.createElement('li')
      const a = document.createElement('a')
      const regex = new RegExp(query, 'i')
      const text = hit.query.replace(regex, '<em>$&</em>')

      li.id = `${ulId}-${index}`
      li.setAttribute('role', 'option')
      li.setAttribute('data-query', hit.query)

      a.innerHTML = text
      a.role = 'button'
      a.onclick = () => searchWithSuggestion(searchCollapse, hit.query, inputField)

      li.appendChild(a)
      ul.appendChild(li)
    })
    inputField.setAttribute('aria-expanded', 'true')
    autocompleteWrapper.classList.remove('d-none');

  } else {
    autocompleteWrapper?.querySelectorAll('ul li').forEach(el => el.remove())
    inputField.setAttribute('aria-expanded', 'false')
    autocompleteWrapper.classList.add('d-none');
  }
}

var updateAutocomplete = (searchCollapse, response, query) => {
  let autocompleteWrapper = searchCollapse.querySelector('.autocomplete-wrapper')
  const inputField = searchCollapse.querySelector('#headerSearchInput')
  inputField.setAttribute('data-original-query', query)
  autocompleteWrapper.querySelectorAll('ul li').forEach(el => el.remove())
  createAutoCompleteList(response, searchCollapse, inputField, autocompleteWrapper, query) 
}

var setVisualFocus = (searchCollapse, keycode) => {
  const searchInput = searchCollapse.querySelector('#headerSearchInput')
  let activeIndex = null;
  const suggestionList = searchCollapse.querySelectorAll('ul li')
  const maxIndex = suggestionList.length - 1
  let newIndex = null

  suggestionList.forEach((li, index) => {
    if (li.classList.contains('focus')) {
      activeIndex = index
    }
    li.classList.remove('focus')
    li.removeAttribute('aria-selected')
  })

  if (keycode === 'ArrowDown') {
    if (activeIndex === null) newIndex = 0
    else if (activeIndex < maxIndex) {
      newIndex = activeIndex + 1
    }
  }

  if (keycode === 'ArrowUp') {
    if (activeIndex === null) newIndex = maxIndex
    else if (activeIndex > 0) newIndex = activeIndex - 1
  }

  if (newIndex !== null) {
    const newItem = suggestionList[newIndex]
    const activeQuery = newItem.getAttribute('data-query')
    searchInput.value = activeQuery
    searchInput.setAttribute('aria-activedescendant', newItem.id)
    newItem.classList.add('focus')
    newItem.setAttribute('aria-selected', 'true')
  } else {
    searchInput.value = searchInput.getAttribute('data-original-query')
    searchInput.setAttribute('aria-activedescendant', '')
  }
}

var autocompleteListener = async (searchCollapse, searchInput, e) => {

  if ((e.code === 'ArrowDown' || e.code === 'ArrowUp') && searchInput.getAttribute('aria-expanded') === 'true') {
    setVisualFocus(searchCollapse, e.code)
  } else if (e.code === 'Escape') {
    closeSearch(searchCollapse)
  } else {
    try {
      if (e.target.value.length > 2) {
        fetch(`/find_v2/_autocomplete?prefix=${e.target.value}&size=3`)
          .then((response) => response.body)
          .then((rb) => {
            const reader = rb.getReader();

            return new ReadableStream({
              start(controller) {
                function push() {
                  reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }
                    controller.enqueue(value);
                    push();
                  });
                }
                push();
              },
            });
          })
          .then((stream) =>
            new Response(stream, { headers: { 'Content-Type': 'application/json' } }).json(),
          )
          .then((result) => {
            updateAutocomplete(searchCollapse, result.hits, e.target.value)
          });
      } else {
        updateAutocomplete(searchCollapse, null)
      }

    } catch (e) {
      // Ignore - Debug: console.error(`Error: ${e}`);
    }
  }
}

var initAutoComplete = (searchCollapse, searchInput) => {
  if (searchCollapse.getAttribute('data-autocomplete-initialized') !== 'true' && searchInput.classList.contains('search-autocomplete')) {
    searchInput.addEventListener('keyup', (e) => { autocompleteListener(searchCollapse, searchInput, e) })
  }
}

var closeListener = (e) => {
  const searchCollapse = e.target.closest('.search.expandable')

  if (!searchCollapse) {
    closeSearch(null)
  } else if (e.target.classList.contains('close-btn') || e.target.closest('.close-btn')) {
    const searchInput = searchCollapse.querySelector('input')
    searchInput.value.length > 0 ? searchInput.value = '' : closeSearch(searchCollapse)
  }
}

var closeSearch = (searchCollapse) => {

  var close = (searchCollapse, closeAll) => {
    const searchInput = searchCollapse.querySelector('input')

    if (!closeAll && searchInput.getAttribute('aria-expanded') === 'true') {
      searchCollapse.querySelectorAll('.autocomplete-wrapper ul li').forEach(el => el.remove())
      searchCollapse.querySelector('.autocomplete-wrapper').classList.add('d-none');
      searchInput.setAttribute('aria-expanded', 'false')
      searchInput.setAttribute('aria-activedescendant', '')
    } else {
      searchInput.setAttribute('aria-activedescendant', '')
      searchCollapse.querySelectorAll('.autocomplete-wrapper ul li').forEach(el => el.remove())
      searchCollapse.querySelector('.autocomplete-wrapper')?.classList.add('d-none');
      searchCollapse.classList.add('collapsed')
      document.body.classList.remove('page-overlay')
      searchInput.value = ''
      searchInput.setAttribute('aria-expanded', 'false')
      document.removeEventListener('click', closeListener)
      searchInput.setAttribute('tabindex', -1)

      const closeBtn = searchCollapse.querySelector('.close-btn')
      const submitButton = searchCollapse.querySelector('button[type=submit]')
      closeBtn.setAttribute('aria-expanded', 'false')
      submitButton.setAttribute('aria-controls', closeBtn.getAttribute('aria-controls'))
      submitButton.setAttribute('aria-expanded', 'false')      
    }
  }

  if (!searchCollapse) {
    document.querySelectorAll('header .search.expandable').forEach((searchCollapse) => {
      close(searchCollapse, true)
    })
  } else {
    close(searchCollapse, false)
  }
}

var searchClickListenerFunction = (el, event) => {
  const searchCollapse = el.closest('.search.expandable')
  const searchInput = searchCollapse.querySelector('input')
  const showPageOverlay = searchCollapse.getAttribute('data-pageoverlay') === 'true';
  const searchBtn = searchCollapse.querySelector('button[type=submit]')
  const isHeaderSearch = el.closest('header') !== null

  if (event && searchInput.value === '') {
    event.preventDefault()
  }

  searchCollapse.classList.remove('collapsed')
  showPageOverlay && document.body.classList.add('page-overlay')
  searchInput.setAttribute('tabindex', searchCollapse.querySelector('.search-btn').attributes.tabindex.value)
  searchInput.focus()
  initAutoComplete(searchCollapse, searchInput)
  searchCollapse.setAttribute('data-autocomplete-initialized', 'true');
  searchBtn.removeAttribute('aria-expanded')
  searchBtn.removeAttribute('aria-controls')
  searchCollapse.querySelector('.close-btn').setAttribute('aria-expanded', 'true')
  event?.stopPropagation()

  if (event) {
    const isInsideSearch = event.target.closest('.search.expandable')

    if (!isInsideSearch || el.classList.contains('close-btn') || el.closest('.close-btn')) {
      closeSearch(searchCollapse)
    }
  }

  if (isHeaderSearch) {
    document.addEventListener('click', closeListener)
  } else {
    searchCollapse.querySelector('.close-btn').addEventListener('click', closeListener)
  }
}

const handleSearchClick = (e) => {
  searchClickListenerFunction(e.target, e)
}

// find all search buttons and init
var initSearch = () => {
  var searchBtns = document.querySelectorAll('header .search-btn, .listing-page-init .search-btn')

  searchBtns.forEach(el => {
    el.addEventListener('click', handleSearchClick);

    if (el.classList.contains('listing-search') && el.previousElementSibling?.value !== '') {
      searchClickListenerFunction(el, null)
    }
  })
}

// init after loading
document.addEventListener('DOMContentLoaded', initSearch);