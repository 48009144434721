(() => {
  'use strict'

  const forms = document.querySelectorAll('.needs-validation')
 
  const validateCheckboxes = (form) => {

    const setValidity = (inputset, isValid, inputChangeHandler) => {
      inputset.forEach((input) => {
        input.setCustomValidity(isValid ? '' : "invalid")
        isValid ? input.removeEventListener('change', inputChangeHandler) : input.addEventListener('change', inputChangeHandler)
      })
    }

    const fieldsetToBeValidated = form.querySelectorAll('fieldset.validate-checkboxes')

    const checkChecked = (set, name) => {
      return set.querySelectorAll(`input[name="${name}"]:checked`).length > 0
    }

    if (fieldsetToBeValidated.length > 0) {
      fieldsetToBeValidated.forEach((set) => {
        const nameAttr = set.querySelector('input[type="checkbox"]').getAttribute('name')
        const isValid = checkChecked(set, nameAttr)
        const inputset = set.querySelectorAll(`input[name="${nameAttr}"]`)
        isValid ? set.classList.remove('invalid') : set.classList.add('invalid')
       
        const inputChangeHandler = () => {
          const isV = checkChecked(set, nameAttr)
          setValidity(inputset, isV, inputChangeHandler)
          isV ? set.classList.remove('invalid') : set.classList.add('invalid')
        }

        setValidity(inputset, isValid, inputChangeHandler)

        return isValid
      })
    }
    return true
  }

  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity() && validateCheckboxes(form)) {
        event.preventDefault()
        event.stopPropagation()
      }
      form.classList.add('was-validated')
    }, false)
  })
})()