// Import just what we need

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/button'
import Carousel from 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
import Modal from 'bootstrap/js/dist/modal'
import { createCarousel } from './image-gallery'
import { getExpert } from './experts-modal'

const campaignPopup = document.querySelector('#CampaignPopup')

if (campaignPopup) {
  let showPopup = true
  const campaingId = campaignPopup.getAttribute('data-campaignid')
  const localstorageValue = window.localStorage.getItem(`campaignPopup-${campaingId}`)

  if (localstorageValue) {
    try {
      let lastYear = new Date()
      lastYear.setFullYear(lastYear.getFullYear() - 1)

      if (new Date(localstorageValue) > lastYear) {
        showPopup = false
      } else {
        window.localStorage.removeItem(`campaignPopup-${campaingId}`)
      }

    } catch {
      // ignore on purpose
    }
  }
 
  if (showPopup) {
    const campaignModal = Modal.getOrCreateInstance('#CampaignPopup')
    campaignModal.show()
    window.localStorage.setItem(`campaignPopup-${campaingId}`, new Date())
  }
}

// form block modal autoshow

document.querySelectorAll('.form-block-modal .modal').forEach((modal) => {
  const modalLocalstorageValue = window.localStorage.getItem(modal.id)
  const show = modalLocalstorageValue && modalLocalstorageValue === 'true'

  if (show) {
    const formBlockModal = Modal.getOrCreateInstance(`#${modal.id}`)
    formBlockModal.show()
  }

  window.localStorage.removeItem(modal.id)
}) 

// Image Gallery Carousel

const closeGalleryHandler = (e) => {
  if (!e.target.closest('.carousel') || e.target.classList.contains('icon_close') || e.target.classList.contains('.close-btn')) {
    e.target.closest('.image-gallery').remove()
    document.querySelectorAll('.image-carousel').forEach((item) => {
      item.setAttribute('data-gallery-open', 'false')
      item.querySelectorAll('a').forEach((el) => el.setAttribute('aria-expanded', 'false'))
    })
    document.body.style.overflow = ''
  }
}

const handleImageGalleryClick = (e) => {
  e.preventDefault()
  const imageCarousel = e.target.closest('.image-carousel')
  const carouselId = imageCarousel.getAttribute('data-block-id')
  if (imageCarousel.getAttribute('data-gallery-open') !== 'true') {
    document.querySelectorAll('.image-carousel').forEach((item) => {
      item.setAttribute('data-gallery-open', 'true')
      item.querySelectorAll('a').forEach((el) => el.setAttribute('aria-expanded', 'true'))
    })

    document.body.style.overflow = 'hidden'
    const galleryCarousel = createCarousel(e.target, carouselId)
    galleryCarousel.addEventListener('click', closeGalleryHandler)
    e.target.closest('.image-gallery-block').after(galleryCarousel)

    const carousel = new Carousel(`#imageGalleryCarousel_${carouselId}`)
  }
}

document.querySelectorAll('.image-carousel a').forEach((item) => item.addEventListener('click', handleImageGalleryClick))

// Meet our experts modal

const expertsModals = document.querySelectorAll('.expert-modal')
expertsModals.forEach((modal) =>
  modal.addEventListener('show.bs.modal', event => {
    getExpert(event.target)
  })
)

if (document.querySelector('.hero-carousel-wrapper')) {
  const heroCarousel = new Carousel(`#heroContainer`)
  const pausebtn = document.querySelector('#carousel-pause')
  const playbtn = document.querySelector('#carousel-play')

  pausebtn.addEventListener('click', () => {
    heroCarousel.pause()
    pausebtn.classList.add('d-none')
    playbtn.classList.remove('d-none')
  })

  document.querySelector('#carousel-play').addEventListener('click', () => {
    heroCarousel.cycle()
    pausebtn.classList.remove('d-none')
    playbtn.classList.add('d-none')
  })

  document.querySelectorAll('.carousel-indicators button').forEach((btn) => {
    btn.addEventListener('click', () => {
      pausebtn.classList.remove('d-none')
      playbtn.classList.add('d-none')
    })
  })

  document.addEventListener('scroll', () => {
    window.innerWidth < 992 && document.body.classList.contains('scrolling') ? heroCarousel.pause() : heroCarousel.cycle()
  })
}