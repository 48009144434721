window.addEventListener("OneTrustGroupsUpdated", event => {
  const iframeHostSrcs = ["www.youtube.com", "open.spotify.com"];
  if (!event.detail.includes('C0004')) {
    setupConsentOnIFrames(iframeHostSrcs, true)
  } else {
    setupConsentOnIFrames(iframeHostSrcs, false)
  }
});

// Creates Blocked by cookie settings element
var createEl = () => {
  const optanonDiv = document.createElement('div')
  optanonDiv.classList.add('Optanon-custom', 'block-content', 'd-flex', 'flex-column', 'align-items-center', 'justify-content-center', 'py-4', 'bg-light')

  const h3 = document.createElement('h3')
  h3.innerText = window['consentHeader']

  const p = document.createElement('p')
  p.classList.add('text-center', 'px-4')
  p.innerText = window['consentBody']

  const button = document.createElement('button')
  button.setAttribute('id', 'ot-sdk-btn')
  button.classList.add('ot-sdk-show-settings', 'btn')
  
  button.innerText = window['consentButtonText']
  button.setAttribute('onclick', 'OneTrust.ToggleInfoDisplay()')
  optanonDiv.appendChild(h3)
  optanonDiv.appendChild(p)
  optanonDiv.appendChild(button)

  return optanonDiv
}

var videoBlockPlayHandler = (iframe) => {
  const currentSource = iframe.getAttribute('src');
  iframe.setAttribute('src', currentSource.indexOf('?') !== -1 ? `${currentSource}&autoplay=1` : `${currentSource}?autoplay=1`)
  iframe.style.zIndex = 1;
  iframe.closest('.video-block')?.querySelector('.button-wrapper')?.classList.add('d-none')
}

var setupConsentOnIFrames = (iframeHostSrcs, isIframeBlocked) => {
  document.querySelectorAll('iframe').forEach((iframe) => {
    const hostname = getHostnameFromEmbedUrl(iframe.getAttribute('data-iframe-src'))
    
    if (hostname && iframeHostSrcs.includes(hostname)) {
      let blockerElement = iframe.parentElement.querySelector('.block-content')

      if (!blockerElement) {
        blockerElement = createEl()
        iframe.insertAdjacentElement('afterend', blockerElement);
      }
      const videoPreview = iframe.previousElementSibling?.classList.contains('video-preview') ? iframe.previousElementSibling : null

      if (isIframeBlocked) {
        iframe.classList.add('d-none')
        iframe.src = ""
        videoPreview?.classList.add('d-none')
        blockerElement.classList.add('d-block');
        blockerElement.classList.remove('d-none');
      } else {
        iframe.src = iframe.getAttribute('data-iframe-src')
        blockerElement.classList.add('d-none');
        blockerElement.classList.remove('d-block');
        videoPreview?.classList.remove('d-none')
        iframe.classList.remove('d-none');
        videoPreview?.querySelector('.play-btn').addEventListener('click', () => videoBlockPlayHandler(iframe))
      }
    }
  });
}

var getHostnameFromEmbedUrl = (url) => {
  try {
    return new URL(url).hostname;
  }
  catch (e) {
    return null;
  }
}

// If no One Trust, show iframes.
if (!window['OneTrust']) {
  const iframeHostSrcs = ["www.youtube.com", "open.spotify.com"];
  setupConsentOnIFrames(iframeHostSrcs, false)
}