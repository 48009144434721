import { createHtml } from './common-functions'

export const createCarousel = (carousel, carouselId) => {

  const selectedItem = carousel.tagName === 'IMG' ? carousel.parentElement : carousel
  carousel = carousel.closest('.image-carousel')
  const carouselNextText = carousel.getAttribute('data-next')
  const carouselPreviousText = carousel.getAttribute('data-previous')
  const carouselCloseText = carousel.getAttribute('data-close')
  const carouselTemplateString = `<div id="imageGalleryCarousel_${carouselId}" class="carousel slide" data-bs-touch="true">
    <div class="carousel-inner">
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#imageGalleryCarousel_${carouselId}" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">${carouselPreviousText}</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#imageGalleryCarousel_${carouselId}" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">${carouselNextText}</span>
    </button>
  </div>`

  const galleryString = `<div class="image-gallery d-flex align-items-center justify-content-center">
    <div class="gallery-wrapper position-relative d-flex w-100">
      <button id="close" class="button close-btn bg-none border-0 position-absolute" aria-label="Close">
        <svg class="icon icon_close">
          <title>${carouselCloseText}</title>
          <use xlink: href="#icon_close"></use>
        </svg>
      </button>
      <div class="image-gallery-carousel">
      </div>
    </div>
  </div>` 

  const createCarouselItem = (el, active) => {
    const div = document.createElement('div')
    div.classList.add('carousel-item')
    el === active && div.classList.add('active')
    const imgElement = el.firstElementChild.cloneNode(true)
    imgElement.src = el.getAttribute('data-img-url')
    imgElement.classList.add('w-100')
    div.appendChild(imgElement)

    return div
  }

  const galleryWrapper = createHtml(galleryString)
  const carouselTemplate = createHtml(carouselTemplateString)

  carousel.querySelectorAll('a').forEach((item) => {
    carouselTemplate.querySelector('.carousel-inner').appendChild(createCarouselItem(item, selectedItem))
  })

  galleryWrapper.querySelector('.image-gallery-carousel').appendChild(carouselTemplate)

  return galleryWrapper
}