const searchWrapper = document.querySelector('.searchpage .list-wrapper')

const searchForm = document.querySelector('.hero-search form')

if (searchForm) {
  const searchInput = searchForm.querySelector('input')
  const searchClearBtn = searchForm.querySelector('.close-btn')

  const searchInputHandler = () => { searchInput.value.length > 0 ? searchClearBtn.classList.remove('d-none') : searchClearBtn.classList.add('d-none') }

  searchInputHandler()

  searchInput.addEventListener('change', searchInputHandler)

  searchClearBtn.addEventListener('click', () => {
    searchInput.value = ''
    searchInput.focus()
  })
}

if (searchWrapper) {
  const loadMoreBtn = searchWrapper.querySelector('.load-more-btn')
  const resultlist = searchWrapper.querySelector('ul')

  const getMore = async (url) => {
    searchWrapper.classList.add('loading')

    await fetch(url, {
      method: "GET",
      headers:
      {
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then(res => {
      if (!res.ok && res.status !== 404) {
        return Promise.reject(res);
      }
      return res.status !== 404 ? res.text() : null

    }).then(data => {
      if (!data) return null
      const parser = new DOMParser()
      return parser.parseFromString(data, "text/html")
    }).then((el) => {
      if (el) {
        el.querySelectorAll('.search-result-list li').forEach((result) => resultlist.appendChild(result))

        const newUrl = el.querySelector('.load-more-btn')?.getAttribute('data-url')

        if (newUrl && loadMoreBtn) {
          loadMoreBtn.setAttribute('data-url', newUrl)
          loadMoreBtn.classList.remove('d-none')
        }
        else {
          loadMoreBtn?.classList.add('d-none')
        }

      } else {
        loadMoreBtn?.classList.add('d-none')
      }

      searchWrapper.classList.remove('loading')

    }).catch(error => {
      console.error(`Error from API: ${error.status}
        - ${error.statusText}`)
      listingContainer.classList.remove('loading')
    })
  }

  const loadmoreHandler = (event) => {
    const url = event.target.getAttribute('data-url')
    getMore(url)
  }

  loadMoreBtn?.addEventListener('click', loadmoreHandler)
}