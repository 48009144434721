import { heroHeightCalculator } from "./heroHeightCalculator"

var windowWidth = window.innerWidth
var posBeforeNavOpen = window.scrollY

var handleDesktopNavCollapses = (event) => {
  if (event.target.classList.contains('nav-link')) {
    event.target.closest('.navbar-nav').querySelectorAll('.nav-item').forEach(el => {
      if (el !== event.target) {
        var collapseItem = el.querySelector('.show')

        if (collapseItem) {
          collapseItem.classList.remove('show')
          el.querySelector('button')?.removeAttribute('aria-expanded')
        }
      }
    })
  }
}

var secondarylinksTabIndexesOnMobile = (clearIndexes) => {

  var secnav = document.querySelector('.navbar-secondary')

  secnav.querySelector('.nav-search-btn').setAttribute('tabindex', clearIndexes ? 0 : 1)
  secnav.querySelector('.navbar .search .close-btn').setAttribute('tabindex', clearIndexes ? 0 : 1)
  secnav.querySelector('.language-nav__current').setAttribute('tabindex', clearIndexes ? 0 : 2)
  secnav.querySelector('.navbar-toggler').setAttribute('tabindex', clearIndexes ? 0 : 3)

  var lastIndex = 3 

  secnav.querySelectorAll('.sec-link a').forEach(e => {
    lastIndex++
    e.setAttribute('tabindex', clearIndexes ? 0 : lastIndex)
  })
}

var closeAllSubMenus = (currentSubmenu) => {

  document.querySelectorAll('.nav-item .dropdown-toggle').forEach(e => {
    if (!currentSubmenu || currentSubmenu.id !== e.getAttribute('aria-controls')) {
      e.classList.add('collapsed')
    }
  })

  document.querySelectorAll('.nav-item .sub-menu').forEach(e => {
    if (!currentSubmenu || currentSubmenu.id !== e.getAttribute('aria-controls')) {
      e.classList.remove('show')
    }
  })
}

var moveNavModalsToBody = () => {

  var modals = document.querySelectorAll('.navbar-nav .right-side-lifts .modal, .two-column-contact-block .modal')
  if (modals.length > 0) {
    modals.forEach(function (e) {
      if (!document.querySelector(`body>#${e.id}`)) {
        document.body.appendChild(e)
      } else {
        e.remove()
      }
    })
  }
}

var closeOnClickOutsideHandler = (e) => {
  if (document.querySelector('.sub-menu.collapse.show') && !e.target.closest('.sub-menu')) {
    closeAllSubMenus(null)
  }
}

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.navbar.navbar-expand-lg')) {
    moveNavModalsToBody()

    const mobileNavCollapsible = document.getElementById('navbarDropdown')
    const navHamburger = document.querySelector('.hamburger')

    mobileNavCollapsible.addEventListener('show.bs.collapse', (e) => {
      closeAllSubMenus(e.target)
      posBeforeNavOpen = window.scrollY
      //document.body.classList.remove('scrolling')

      if (window.innerWidth < 992) {
        document.body.classList.add('navbar-open')
        secondarylinksTabIndexesOnMobile(false)
      } else {
        document.addEventListener('click', closeOnClickOutsideHandler)
      }

      navHamburger.classList.add('is-active')
    })

    mobileNavCollapsible.addEventListener('hidden.bs.collapse', event => {
      if (event.target.id === 'navbarDropdown') {
        closeAllSubMenus()
        secondarylinksTabIndexesOnMobile(true)
        document.body.classList.remove('navbar-open')
        navHamburger.classList.remove('is-active')
        if (posBeforeNavOpen === 0) document.body.classList.remove('scrolling')
        window.scrollTo({ top: posBeforeNavOpen, left: 0, behavior: 'instant' })
        document.removeEventListener('click', closeOnClickOutsideHandler)
      }
    })

    document.querySelector('#languageSelectionDropdown')?.addEventListener('show.bs.collapse', (event) => {

      var closeLangNavHandler = (e) => {
        const langWrap = e.target.closest('#langNavWrapper')

        if (!e.target.closest('#langNavWrapper')) {
          const langWrap = document.querySelector('#langNavWrapper')
          langWrap.querySelector('.language-nav').classList.remove('lang-nav-open')
          langWrap.querySelector('button').setAttribute('aria-expanded', 'false')
          langWrap.querySelector('.language-nav__dropdown').classList.remove('show')
          document.removeEventListener('click', closeLangNavHandler)
        }
      }
      document.querySelector('#langNavWrapper .language-nav').classList.add('lang-nav-open')
      document.addEventListener('click', closeLangNavHandler)
    })

    document.querySelector('#languageSelectionDropdown')?.addEventListener('shown.bs.collapse', () => { document.querySelector('#langNavWrapper nav').focus() })

    document.querySelector('#languageSelectionDropdown')?.addEventListener('hide.bs.collapse', () => { document.querySelector('#langNavWrapper .language-nav').classList.remove('lang-nav-open') })

    window.addEventListener('resize', () => {

      if (window.innerWidth !== windowWidth) {
        closeAllSubMenus(null)
        mobileNavCollapsible.classList.remove('show')
        navHamburger.classList.remove('is-active')
        document.body.classList.remove('navbar-open')
        windowWidth = window.innerWidth
      }

    })

    window.addEventListener('scroll', () => {
      const heroContainer = document.querySelector('#heroContainer')
      if (window.scrollY <= heroContainer.scrollHeight - 100) {
        document.body.classList.remove('scrolling');
        heroContainer.classList.contains('hero-block') && heroHeightCalculator()
      }
      else {
        document.body.classList.add('scrolled-after-hero');
        document.body.classList.add('scrolling');
      }
    })

    document.querySelector('.navbar').addEventListener('mouseover', event => {
      if (window.innerWidth >= 992) {
        handleDesktopNavCollapses(event)
      }
    })

    window.addEventListener('keydown', (event) => {
      if (event.code === 'Escape') {
        var collapses = document.querySelectorAll('.nav-item .show')
        if (collapses) {
          collapses.forEach(el => {
            el.classList.remove('show')
            el.parentElement.previousElementSibling.removeAttribute('aria-expanded')
          })
        }
      }
    })
  }
})