(() => {
  'use strict'

  const listingElements = document.querySelectorAll('.listing-page-init')

  listingElements.forEach((filtersContainer) => {

    const clearBtn = filtersContainer.querySelector('.btn-clear-filter')
    const currentUrl = new URL(window.location.pathname, window.location.origin)
    let currentBlockId = filtersContainer.getAttribute('data-block-id')
    currentBlockId = currentBlockId ? `_${currentBlockId}` : ''
    const listingContainerId = `listingContainer${currentBlockId}`
    const listingPageLoadMoreId = `listingPageLoadMore${currentBlockId}`
    const listWrapperId = `listWrapper${currentBlockId}`
    const isJoblisting = filtersContainer.classList.contains('joblisting')

    /**
     * Function to check if select items have values checked and disables and enables clear filter button
     */
    const hasSelectedValues = (filterItems) => {

      if (clearBtn) {
        const notSelected = Array.from(filterItems).every((item) => {
          if (isJoblisting && item.tagName === 'DIV') {
            if (item.querySelectorAll('input:checked').length > 0) {
              return false
            }
          } else {
            if (item.value !== '' && (item.tagName === 'INPUT' || item.value !== item.firstElementChild.value)) {
              return false
            }
          }
          return true
        })

        !notSelected ? clearBtn.classList.remove('disabled') : clearBtn.classList.add('disabled')
      }
    }

    /**
     * Function to update history url and clear select items is event is passed instead of searchParams
     * @param searchParams: type of event or URLSearchParams
     */
    const updateHistoryUrl = (searchParams) => {

      if (searchParams) {
        currentUrl.search = searchParams.toString()
      } else {
        currentUrl.search = ''
      }

      history.pushState(null, null, currentUrl)
    }

    /**
     * Adds Event listeners to joblistingpage filter items
     * @param filterItems: Nodelist of filter items
     */
    const addEventListernersToJoblistingFilterItems = (filterItems) => {

      const filterHandler = () => {
        let searchParams = new URLSearchParams()

        filterItems.forEach((item) => {
          if (item.tagName !== 'DIV') {
            item.value !== '' && searchParams.set(item.name, item.value)
          } else {
            const key = item.getAttribute('data-key')
            const valueArr = []
            item.querySelectorAll('input:checked').forEach(input => valueArr.push(input.value))
            valueArr.length > 0 && searchParams.set(key, valueArr.join(';'))
          }
        })

        hasSelectedValues(filterItems)
        updateHistoryUrl(searchParams)
        updateJoblistingFilters(searchParams)
        const urlToFetch = currentUrl
        urlToFetch.search = searchParams.toString()
        getMoreOrFilter(urlToFetch.href)
      }

      filterItems.forEach((item) => {
        if (item.tagName !== 'DIV') {
          item.addEventListener('change', filterHandler)
        } else {
          item.querySelectorAll('input').forEach(input => input.addEventListener('change', filterHandler))
        }
      })
    }

    /**
     * Adds Event listeners to filter items
     * @param filterItems: Nodelist of filter items
     */ 
    const addEventListernersToFilterItems = (filterItems) => {

      const filterHandler = () => {
        let searchParams = new URLSearchParams()

        filterItems.forEach((item) => {
          item.value !== '' && searchParams.set(item.name, item.value)
        })

        hasSelectedValues(filterItems)
        updateHistoryUrl(searchParams)
        updateFilters(searchParams)
        const urlToFetch = currentUrl
        urlToFetch.search = searchParams.toString()
        getMoreOrFilter(urlToFetch.href)
      }

      filterItems.forEach((item) => item.addEventListener('change', filterHandler))
    }

    /**
    * Updates joblistingpage city filter values
    * @param filterItem: new filters
    */
    const updateCityFilter = (citySelect) => {
      const cityFilter = filtersContainer.querySelector('select[name="city"]')
      cityFilter.querySelectorAll('option:not(:first-child)').forEach((filter) => {
        filter.remove()
      })

      const newOptions = citySelect.querySelectorAll('option[value]:not([value=""])')
      if (newOptions?.length > 0) {
        newOptions.forEach((option) => {
          if (!cityFilter.querySelector(`option[name=${option.getAttribute('name')}]`)) {
            cityFilter.appendChild(option)
          }
        })
        cityFilter.removeAttribute('disabled')
      } else {
        cityFilter.setAttribute('disabled', '')
      }
    }

    /**
     * Clears checked input per search parameters
     * @param filtersContainer
     * @param searchParams: Search parameters
     */
    const clearJoblistingChecks = (filtersContainer, searchParams) => {
      filtersContainer.querySelectorAll('.job-filter-dropdown')?.forEach((dropdownItem) => {
        if (!searchParams.has(dropdownItem.getAttribute('data-key'))) {
          dropdownItem.querySelectorAll('input').forEach((input) => input.checked = false)
          dropdownItem.querySelector('button').innerText = dropdownItem.getAttribute('data-default-label')
        }
      })
    }

    /**
    * Updates joblistingpage filters
    * @param searchParams: Current searchparams
    */
    const updateJoblistingFilters = async (searchParams) => {
      if (!searchParams || searchParams.size === 0) {
        filtersContainer.querySelectorAll('.job-filter-dropdown')?.forEach((dropdownItem) => {
          dropdownItem.querySelectorAll('input').forEach((input) => input.checked = false)
          dropdownItem.querySelector('button').innerText = dropdownItem.getAttribute('data-default-label')
        })
        filtersContainer.querySelectorAll('.filters select')?.forEach((select) => {
          select.value = select.firstElementChild.value
          select.setAttribute('disabled', '')
        })
      } else {
        clearJoblistingChecks(filtersContainer, searchParams)

        searchParams.forEach((value, key) => {
          const dropdownItem = filtersContainer.querySelector(`.job-filter-dropdown[data-key="${key}"]`)

          if (dropdownItem) {
            const valueArr = value.split(';')
            const valueArrLength = valueArr.length
            let newlabel

            if (valueArrLength === 0) {
              newlabel = dropdownItem.getAttribute('data-default-label')
            } else if (valueArrLength === 1) {
              newlabel = dropdownItem.querySelector('input:checked').labels[0].innerText.trim()
            } else {
              newlabel = dropdownItem.getAttribute('data-label').replace('{0}', valueArrLength)
            }
            dropdownItem.querySelector('button').innerText = newlabel
          }
        });
      }
    }

    /**
    * Updates filters
    * @param searchParams: Current searchparams
    */
    const updateFilters = async (searchParams) => {
      const updateValues = (name, values, selected) => {
        if (values) {
          const selectEl = filtersContainer.querySelector(`select[name="${name}"]`)
          selectEl.querySelectorAll('option:not(:first-child)').forEach(el => el.remove())

          Object.keys(values).forEach((val) => {
            const option = document.createElement('option')
            let currentValue
            let currentText

            // TODO: Harmonize results from BE.
            if (!isNaN(parseInt(val))) {
              currentText = values[val]?.LocalizedDescription ? values[val].LocalizedDescription : values[val]
              currentValue = values[val]?.Name ? values[val].Name : values[val]
            } else {
              currentText = values[val]
              currentValue = val
            }

            option.innerText = currentText
            option.setAttribute('value', currentValue)
            currentValue === selected && option.setAttribute('selected', 'selected')
            selectEl.appendChild(option)
          })
        }
      }

      if (document.querySelector('.releaselistingpage #refreshableFilters')) {
        // for refreshable filters in Releaselistingpage
        const url = `${currentUrl.pathname}/RefreshFilters?${searchParams && searchParams.toString()}`
        await fetch(url, {
          method: "GET",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          }
        }).then(res => res.json()).then(data => {
          try {
            // TODO: Harmonize results from BE.
            updateValues('company', data?.Companies, data?.CompanyFilter)
            // updateValues('industry', data?.Industries, data?.IndustryFilter)
            updateValues('type', data?.ReleaseTypes, data?.TypeFilter)
            updateValues('year', data?.Years, data?.YearFilter)
          } catch (e) {
            console.error(`Filter update failed: ${e} `)
          }
        })
      } else if (!searchParams && (document.body.classList.contains('eventlistingpage') || document.body.classList.contains('webinarlistingpage') || document.body.classList.contains('caselistingpage'))) {
        filtersContainer.querySelectorAll('.filters select')?.forEach((select) => { select.value = select.firstElementChild.value })
      }
    }

    /**
     * Increases skip value for load more btn
     *  @param loadmoreBtn Load more button element
     */
    const increareSkipCount = (loadmoreBtn) => {
      try {
        let newLoadMoreUrl = new URL(loadmoreBtn.getAttribute('data-url'), currentUrl.origin)
        const skipCount = parseInt(loadmoreBtn.getAttribute('data-skip-count'))
        const currentSkip = parseInt(newLoadMoreUrl.searchParams.get('skip'))
        newLoadMoreUrl.searchParams.set('skip', `${currentSkip + skipCount}`)
        return newLoadMoreUrl
      } catch {
        console.error('Failed to increase load more skip count.')
        return null
      }
    }

    /**
     * Gets more items or filters items
     * @param url Url where to fetch
     * @param isGetMore true if getmore 
     */
    const getMoreOrFilter = async (url) => {
      filtersContainer.classList.add('loading')
      let resultContainer = filtersContainer.querySelector(`#${listingContainerId} .result-container`)
      const isGetMore = url.indexOf('skip=') !== -1 || url.indexOf('loadAfter=') !== -1

      if (!isGetMore) {
        url = url.indexOf('?') === -1 ? `${url}?filters=true` : `${url}&filters=true`
      }

      await fetch(url, {
        method: "GET",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        }
      }).then(res => {
        if (!res.ok && res.status !== 404) {
          return Promise.reject(res);
        }
        return res.status !== 404 ? res.text() : null

      }).then(data => {
        if (!data) return null 
        const parser = new DOMParser()
        return parser.parseFromString(data, "text/html")
      }).then((el) => {
        // add new items to view
        if (!isGetMore && el) {
          let resultListSelector = 'body > div'

          // if results contains section element
          if (el.querySelector('section')) {
            resultListSelector = `#${listingContainerId} > div:not(.loader)`
          }

          filtersContainer.querySelectorAll(`#${listingContainerId} > div:not(.loader)`).forEach(el => el.remove())
          el.querySelectorAll(resultListSelector).forEach((el) => filtersContainer.querySelector(`#${listingContainerId}`).appendChild(el))
          isJoblisting && updateCityFilter(el.querySelector('select[name="city"]'))
          addLoadMoreEventlistener()
        } else if (el) {
          const isLatestBlogsByCategoryFilterBlock = filtersContainer.classList.contains('latest-blogs-by-category-filter-block')
          const itemSelector = isLatestBlogsByCategoryFilterBlock ? 'ul li' : isJoblisting ? `#${listWrapperId} .job-item` : `#${listWrapperId} ul li`

          el.querySelectorAll(itemSelector).forEach((li) => resultContainer.appendChild(li))
          const listingLoadMoreBtn = filtersContainer.querySelector(`#${listingPageLoadMoreId}`)
          const newLoadMoreUrl = !isLatestBlogsByCategoryFilterBlock ? el.querySelector('.load-more-btn')?.getAttribute('data-url') : increareSkipCount(listingLoadMoreBtn)
          if (newLoadMoreUrl && listingLoadMoreBtn) {
            listingLoadMoreBtn.setAttribute('data-url', newLoadMoreUrl)
            listingLoadMoreBtn.classList.remove('d-none')
          } else {
            listingLoadMoreBtn?.classList.add('d-none')
          }
        } else {
          filtersContainer.querySelector(`#${listingPageLoadMoreId}`)?.classList.add('d-none')
        }

        filtersContainer.classList.remove('loading')
      }).catch(error => {
        console.error(`Error from API: ${error.status} - ${error.statusText}`)
        filtersContainer.classList.remove('loading')
      })
    }

    /**
     * Adds event listener to load more button
     */ 
    const addLoadMoreEventlistener = () => {
      filtersContainer.querySelector(`#${listingPageLoadMoreId}`)?.addEventListener('click', function (event) {
        const url = event.target.getAttribute('data-url')
        getMoreOrFilter(url)
      })
    }

    const clearBtnClickHandler = () => {
      filtersContainer.querySelectorAll('input.form-control')?.forEach((input) => { input.value = '' })
      updateHistoryUrl(null)
      isJoblisting ? updateJoblistingFilters(null) : updateFilters(null)
      getMoreOrFilter(currentUrl.pathname)
      clearBtn.classList.add('disabled')
    }

    const addEventlistenerToSearchClearBtn = (filterItems) => {
      const searchClearBtnClickHandler = () => {
        const oldSearchParamsString = currentUrl.searchParams.toString()
        let searchParams = new URLSearchParams()

        filterItems.forEach((item) => {
          item.tagName !== 'INPUT' && item.value !== '' && searchParams.set(item.name, item.value)
        })

        hasSelectedValues(filterItems)
        updateHistoryUrl(searchParams)
        updateFilters(searchParams)
        const urlToFetch = currentUrl
        const newSearchParamsString = searchParams.toString()

        if (oldSearchParamsString !== newSearchParamsString) {
          urlToFetch.search = newSearchParamsString
          getMoreOrFilter(urlToFetch.href)
        }
      }

      filtersContainer.querySelector('.search .close-btn')?.addEventListener('click', searchClearBtnClickHandler)
    }

    const inputItems = isJoblisting ? filtersContainer.querySelectorAll('.job-filter-dropdown, select, .search input') : filtersContainer.querySelectorAll('select, .search input')
    hasSelectedValues(inputItems)
    isJoblisting ? addEventListernersToJoblistingFilterItems(inputItems) : addEventListernersToFilterItems(inputItems)
    clearBtn?.addEventListener('click', clearBtnClickHandler)
    addLoadMoreEventlistener()
    addEventlistenerToSearchClearBtn(inputItems)
  })
})()